import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  MouseEvent,
  useState,
  useEffect,
} from 'react';
import { Button as MuiButton, Typography, styled } from '@mui/material';
import { useTheme } from '@mui/styles';
import ReactLoading from 'react-loading';
import { StyledProps } from 'types/styled';

import ShareModal from './ShareModal';
import { ReactComponent as ShareProfile } from 'images/icons/ShareProfile.svg';
import {
  checkQCardRNAppDevice,
  postMessageToQCardApp,
} from 'utils/qcardReactNativeHandlers';
import { DefaultColors } from 'constants/colors';
import classNames from 'classnames';
import { useProfile } from 'hooks/useProfile';

const CompanyCTAButton = styled(MuiButton, {
  shouldForwardProp: (prop) =>
    prop !== 'ctaColor' && prop !== 'bgCtaColor' && prop !== 'typeVariant',
  name: 'CompanyCTAButton',
  slot: 'Root',
  overridesResolver: (props) => [
    props.typeVariant === 'primary' && {
      background: props.bgCtaColor,

      '&:hover': {
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${props.bgCtaColor}`,
      },

      '& .qrcode-container': {
        color: props.ctaColor,
        borderColor: props.ctaColor,
      },
      '&.MuiButton-root.share-btn': {
        '&:hover': {
          background: props.bgCtaColor,
        },
        '& p:hover': {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${props.bgCtaColor}`,
        },
        '& .qrcode-container:hover': {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${props.bgCtaColor}`,
        },
      },
    },
    props.typeVariant === 'secondary' && {
      background: props.bgCtaColor ?? DefaultColors.WHITE,
      color: props.ctaColor,
      '&:hover': {
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${
          props.bgCtaColor ?? DefaultColors.WHITE
        }`,
      },
      '& > svg': {
        fill: props.ctaColor,
        stroke: props.ctaColor,
      },
      '& .qrcode-container': {
        borderColor: props.ctaColor,
      },
      '&.MuiButton-root.share-btn': {
        '&:hover': {
          background: props.bgCtaColor ?? DefaultColors.WHITE,
        },
        '& p:hover': {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${
            props.bgCtaColor ?? DefaultColors.WHITE
          }`,
        },
        '& .qrcode-container:hover': {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${
            props.bgCtaColor ?? DefaultColors.WHITE
          }`,
        },
      },
    },
    props.typeVariant === 'text' && {
      background: 'transparent',
      border: 'none',
      color: DefaultColors.SECONDARY_TEXT,
      justifyContent: 'flex-start',
      height: 24,
      padding: 0,
      transitionProperty: 'all',
      transitionTimingFunction: 'linear',
      transitionDuration: '250ms',
      borderRadius: 0,
      '&:hover': {
        background: 'transparent',
      },
      '&.MuiButton-root.share-btn': {
        '&:hover': {
          background: 'transparent',
        },
        '& p:hover': {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${DefaultColors.WHITE}`,
        },
        '& .qrcode-container:hover': {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${DefaultColors.WHITE}`,
        },
      },
    },
    props.typeVariant === 'replace' && {
      background: DefaultColors.WARNING,
      color: DefaultColors.WHITE,
      border: 'none',
      '&:hover': {
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${DefaultColors.WHITE}`,
        color: DefaultColors.BLACK,
      },
      '& > svg': {
        fill: DefaultColors.WARNING,
        stroke: DefaultColors.WARNING,
      },
      '& .qrcode-container': {
        borderColor: DefaultColors.WARNING,
      },
    },
  ],
})<{ ctaColor?: string; bgCtaColor?: string; typeVariant: string }>(
  ({ ctaColor }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
    borderRadius: 200,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: ctaColor,
    cursor: 'pointer',
    position: 'relative',
    '& .qrcode-container': {
      position: 'absolute',
      right: 0,
      top: -1,
      bottom: -1,
      padding: '0 16px',
      borderWidth: 1,
      borderLeftStyle: 'solid',
      display: 'flex',
      alignItems: 'center',
    },
    '&.MuiButton-root.share-btn': {
      padding: 0,
      overflow: 'hidden',
      flex: '0 0 auto !important',

      '& p': {
        marginRight: '56px',
        padding: '14px',
      },
    },
    buttonLabel: {
      overflow: 'hidden',
      wordWrap: 'break-word',
      padding: '10px 10px 10px 8px',
    },
    loadingState: {},
  })
);

interface ButtonProps extends StyledProps {
  title: string;
  variant?: string;
  icon?: ReactNode | null;
  fontClassName?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  loading?: boolean;
  id?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  onClickQRCode?: boolean;
  shareIconTitle?: string;
  shareIconLabel?: string;
  codeUrl?: string;
  defaultTheme?: boolean;
  ctaColor?: string;
  bgCtaColor?: string;
}

export const Button = ({
  title,
  variant = 'primary',
  icon,
  fontClassName,
  className,
  type = 'button',
  disabled = false,
  loading = false,
  id = '',
  onClick,
  onClickQRCode,
  shareIconTitle = 'Profile',
  shareIconLabel = '',
  codeUrl = '',
  defaultTheme = false,
  ctaColor = DefaultColors.PRIMARY,
  bgCtaColor,
}: PropsWithChildren<ButtonProps>): ReactElement => {
  const theme = useTheme();
  const { profile } = useProfile();
  const [codeQRModal, setCodeQRModal] = useState<string>('');
  const [shareModal, setShareModal] = useState(false);

  useEffect(() => {
    setCodeQRModal(codeUrl);
  }, [codeUrl]);

  const handleShare = async () => {
    if (checkQCardRNAppDevice()) {
      postMessageToQCardApp('qrCodeModalShare', {
        type: 'url',
        value: codeQRModal,
      });
    } else {
      try {
        if (navigator.share) {
          await navigator.share({
            url: codeQRModal,
            title: `Professional Profile of ${profile?.metatag?.title}`,
            text: `Hi, click the link to view the professional profile of ${profile?.metatag?.title}.`,
          });
        } else {
          console.log('navigator.share is not supported');
        }
      } catch (error: any) {
        if (error.name === 'AbortError') {
          console.log('The sharing operation was aborted.');
        } else {
          console.error('Error sharing:', error.message);
        }
      }
    }
  };

  return (
    <>
      <CompanyCTAButton
        type={type}
        disabled={disabled || loading}
        onClick={onClick}
        id={id}
        className={classNames(className, !!onClickQRCode && 'share-btn')}
        aria-label={title}
        typeVariant={variant}
        ctaColor={ctaColor}
        bgCtaColor={bgCtaColor}
      >
        {loading ? (
          <div style={{ margin: '6px 0' }}>
            <ReactLoading
              type="spokes"
              width={28}
              height={28}
              color={variant === 'primary' ? theme.palette.common.white : ctaColor}
            />
          </div>
        ) : (
          <>
            {!!icon && icon}
            <Typography
              fontWeight={700}
              fontSize={16}
              className={fontClassName}
              style={!!onClickQRCode ? { marginRight: 54, width: '100%' } : {}}
              sx={{
                overflow: 'hidden',
                wordWrap: 'break-word',
                padding: '10px 0 10px 0',
                color: ctaColor ?? theme.palette.common.white,
              }}
            >
              {title}
            </Typography>
            {!!onClickQRCode && (
              <div
                className="qrcode-container"
                onClick={(event) => {
                  event.stopPropagation();
                  setShareModal(true);
                }}
              >
                <ShareProfile
                  cursor="pointer"
                  width={24}
                  height={24}
                  fill={
                    variant === 'primary'
                      ? theme.palette.common.white
                      : theme.palette.primary.main
                  }
                />
              </div>
            )}
          </>
        )}
      </CompanyCTAButton>
      <ShareModal
        open={shareModal}
        code={codeQRModal}
        children={icon}
        handleOpen={() => setShareModal(true)}
        handleClose={() => setShareModal(false)}
        title={shareIconTitle}
        shareLabel={shareIconLabel}
        handleShare={handleShare}
        vcf={true}
      />
    </>
  );
};
